<template>
  <header class="sidebar">
    <router-link class="system-info" :to="{name: 'admin.dashboard'}">
      <div class="icon"><img src="@/assets/img/logo.svg" alt="Logo"></div>
      <div class="name"><h2>Wilms</h2></div>
    </router-link>
    <nav id="headerMenuApp">
      <ul>
        <li :key="i" v-for="(menuItem, i) in menuItems">
          <router-link v-if="checkPermissions(menuItem.permissions ? menuItem.permissions : [])" :class="{'active': menuItem.route === $route.name}" :to="{name: menuItem.route}">{{ $t(menuItem.title) }}</router-link>
        </li>
      </ul>
    </nav>

    <router-link class="logout" :to="{ name: 'ww.logout', params: { locale: 'nl' } }">{{ $t('Uitloggen') }}</router-link>
  </header>
</template>

<script>
export default {
  data () {
    return {
      menu_open: false,
      menuItems: [
        {
          route: 'admin.dashboard',
          title: 'dashboard.title'
        },
        {
          route: 'admin.appointments',
          title: 'appointments.title',
          permissions: ['appointment.view']
        },
        {
          route: 'admin.trainings',
          title: 'trainings.title',
          permissions: ['academy_training.view']
        },
        {
          route: 'admin.dealers',
          title: 'dealers.title',
          permissions: ['dealer.view']
        },
        {
          route: 'admin.users',
          title: 'users.title',
          permissions: ['user.view']
        },
        {
          route: 'admin.roles',
          title: 'roles.title',
          permissions: ['role.view']
        },
        {
          route: 'admin.menuitems',
          title: 'menuitems.title',
          permissions: ['menu.edit']
        },
        {
          route: 'admin.news',
          title: 'news.title',
          permissions: ['news.view']
        },
        {
          route: 'admin.loyaltyadvantages',
          title: 'loyaltyadvantages.title',
          permissions: ['loyalty_advantage.view']
        },
        {
          route: 'admin.loyaltyupgrades',
          title: 'loyaltyupgrades.title',
          permissions: ['loyalty_upgrade.view']
        },
        {
          route: 'admin.b2b_orders',
          title: 'b2b_orders.title',
          permissions: ['general.view']
        },
        {
          route: 'admin.products',
          title: 'products.title',
          permissions: ['b2b_orders_product.view']
        },
        {
          route: 'admin.globals',
          title: 'globals.title',
          permissions: ['general.view']
        },
        {
          route: 'admin.logs',
          title: 'logs.title',
          permissions: ['log.view']
        },
        {
          route: 'admin.usertrackings',
          title: 'user_trackings.title',
          permissions: ['user_tracking.view']
        },
        {
          route: 'admin.calculator.settings',
          title: 'calculator_settings.title',
          permissions: ['superadmin']
        }
      ]
    }
  }
}
</script>
