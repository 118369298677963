<template>
  <div>
    <div class="global-container">
      <Header/>

      <main class="content">
        <div class="main-wrapper">
          <div class="global-header">
            <button class="nav-toggle" @click="toggleMenu()">
              <span class="bar"></span>
              <span class="bar"></span>
              <span class="bar"></span>
            </button>

            <div class="user-menu" v-if="currentUser">
              <div class="button" v-click-outside="closeUserMenu" @click="openUserMenu">
                <img width="38" alt="User avatar" src="~@/assets/img/user.svg"/>
                <span class="name">{{ currentUser.profile.first_name ? currentUser.profile.first_name : currentUser.email }}</span>
              </div>

              <div class="dropdown" :class="{'active': menuOpen}">
                <router-link :to="{ name: 'ww.dashboard', params: { locale: 'nl' } }">{{ $t('Dashboard') }}</router-link>
                <router-link :to="{ name: 'ww.profile', params: { locale: 'nl' } }">{{ $t('Profiel') }}</router-link>
                <router-link :to="{ name: 'ww.logout', params: { locale: 'nl' } }">{{ $t('Afmelden') }}</router-link>
              </div>
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <router-view :key="$route.fullPath"></router-view>
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/admin/Header'
import { UserPermissions } from '@/permissions'
import i18n from '@/i18n'

export default {
  name: 'Layout',
  components: {
    Header
  },
  data () {
    return {
      menuOpen: false
    }
  },
  async beforeMount () {
    if (!UserPermissions.check(['user.accessAdminPanel'])) {
      await this.$router.push({ name: 'ww.dashboard', params: { locale: i18n.locale } })
    }

    this.activeLocale = 'nl'
    this.$i18n.locale = this.activeLocale
  },
  mounted () {
    document.body.classList.remove('ui')
    document.body.classList.add('admin')
  },
  methods: {
    toggleMenu () {
      document.body.classList.toggle('showing-nav')
    },
    openUserMenu () {
      this.menuOpen = true
    },
    closeUserMenu () {
      this.menuOpen = false
    }
  }
}
</script>
